<template>
  <div class="speakers-container" @click="collapseSpeaker()">
    <div class="speakers-header">
      <p v-if="!isOpen" class="speakers-title">{{ $t("meetSpeakers") }}</p>
      <font-awesome-icon
        v-if="!isOpen"
        class="arrow-speakers-icon"
        icon="chevron-right"
      ></font-awesome-icon>
    </div>
    <div v-if="isOpen" class="speakers-collapse ml-3 mr-3" dimension="width">
      <h2 class="mt-3 mb-5">{{ $t("meetSpeakers") }}</h2>
      <div v-for="(speaker, index) in speakers" :key="index">
          <b-avatar
            class="speaker-avatar"
            variant="info"
            :text="speaker.name.charAt(0)"
            :src="speaker.avatar"
          ></b-avatar>
          <div class="speaker-info">
            <a class="name" :href="speaker.url" target="_blank">{{ speaker.name }}</a>
            <p class="description">{{ speaker.description }}</p>
          </div>
      </div>
      <font-awesome-icon
        v-if="isOpen"
        class="arrow-speakers-icon-collapse"
        icon="chevron-left"
      ></font-awesome-icon>
    </div>
  </div>
</template>
<script>
export default {
  props: ["speakers"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    collapseSpeaker() {
      this.isOpen = !this.isOpen;
      this.$emit("collapse", this.isOpen);
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.speakers-container {
  background-color: $color-primary;
}

.speakers-title {
  margin-top: 20px;
  writing-mode: vertical-rl;
  color: $text-color-header;
  text-transform: uppercase;
  text-orientation: upright;
}

.arrow-speakers-icon {
  color: $text-color-title;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  font-size: 28px;
}

.arrow-speakers-icon-collapse {
  position: absolute;
  right: 15px;
  float: left;
  color: $text-color-title;
  font-size: 28px;
}

.speakers-collapse {
  float: left;
  p,
  h2 {
    color: $text-color-title;
  }
}

.speaker-avatar {
  display: inline-block;
  vertical-align: top;
}

.speaker-info {
  .name {
    text-transform: uppercase;
    margin-bottom: 5px;
    color: $color-secondary;
  }
  margin-left: 12px;
  display: inline-block;
}
</style>
