<template>
  <div class="timeline-schedule-container">
    <div class="timeline">
      <div class="items-container">
        <div class="time-slot now">
          <!-- <img
          class="play-button"
          src="http://clipart-library.com/images/6Tp5aga7c.png"
        /> -->
          <font-awesome-icon
            class="play-button"
            icon="play-circle"
          ></font-awesome-icon>
          <div class="current-text-container">
            <p class="current-text">{{$t('watchingNow')}}</p>
            <p>{{ schedule.items[currentIndex].title[locale] }}</p>
          </div>
        </div>
        <div v-if="currentIndex + 2 < schedule.items.length"  class="time-slot next">
          <!-- <font-awesome-icon class="play-button" icon="arrow-circle-right"></font-awesome-icon> -->
          <div class="current-text-container">
            <p class="current-text">{{$t('upNext')}}</p>
            <p>{{ schedule.items[currentIndex+1].title[locale] }}</p>
          </div>
        </div>
        <div class="time-slot full-schedule">
          <div v-b-toggle="`schedule-collapse`">
            <span class="when-open">
            <font-awesome-icon
              class="play-button"
              icon="angle-up"
            ></font-awesome-icon>
            </span>
            <span class="when-closed">
            <font-awesome-icon
              class="play-button"
              icon="angle-down"
            ></font-awesome-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="schedule-collapse" class="schedule-container">
      <Schedule :currentIndex="currentIndex" :selected="selected" />
    </b-collapse>
  </div>
</template>
<script>
import moment from "moment";
import Schedule from "@/components/landing/Schedule.vue";

export default {
  props: ["selected"],
  components: {
    Schedule,
  },
  data() {
    return {
      currentIndex: null
    };
  },
  async created() {
    await this.doFetchAll();
    this.calculateTimes();
  },
  methods: {
    async doFetchAll() {
      await this.$store.dispatch("Schedule/fetchAll");
    },
    calculateTimes() {
      let now = moment();
      this.schedule.items.forEach((item, index) => {
        let start = moment(item.start);
        let end = moment(item.stop);
        if (now.isBetween(start, end)) {
          this.currentIndex = index;
        }
      });
    },
  },
  computed: {
    schedule() {
      return this.$store.getters["Schedule/byTitle"](this.selected);
    },
    schedules() {
      return this.$store.state.Schedule.all;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);


.timeline-schedule-container {
  width: 100%;
}

.timeline {
  height: 100px;
  width: 100%;
  background-color: $color-primary;
  color: $text-color-header;
}

.items-container {
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
}

.time-slot {
  display: flex;
  flex-direction: row;
  line-height: 0.5;
  text-transform: uppercase;
  font-size: 25px;
  padding: 15px;

  .play-button {
    width: 45px;
    height: 45px;
    margin-right: 20px;
  }
  .current-text-container {
    display: flex;
    flex-direction: column;
    .current-text {
      font-size: 15px;
    }
  }
}

.schedule-container {
  height: 100%;
  display: block;
  position: relative;
}

.next {
  // background-color: $color-secondary;
  align-items: right;
}
.now {
  flex-grow: 1;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>