<template>
  <b-button class="qr-button" v-b-modal.modal-qr >
    <span class="mr-3">{{$t('QRbutton')}}</span>
    <font-awesome-icon
            class="label-icon"
            icon="qrcode"
    ></font-awesome-icon>
    <b-modal id="modal-qr" body-class="center-content" role="dialog" centered hide-footer :title='$t("QRtitle")'>
      <qrcode class="mobile-qr" :options="{ width: 300 }" :value="QRLink"></qrcode>
    </b-modal>
  </b-button>
</template>
<script>
export default {
  name: "ChatQR",
  props: ['currentUser', 'livestream'],
  computed: {
    QRLink() {
      let link = "";
      let firstname = btoa(this.currentUser.first_name)
      let lastname = btoa(this.currentUser.last_name)
      let email = btoa(this.currentUser.email)
      let livestreamId = btoa(this.livestream.id)
      
      const url = this.$router.resolve({
        name: 'mobileChat',
        query: { firstname, lastname, email, livestreamId } 
      })

      link = `${process.env.VUE_APP_BASE_URL}${url.href}`
      
      console.log(link)

      return link;
    },
  },
};
</script>
<style>
.center-content {
  align-content: center;
  text-align: center;
  padding: 0px !important;
}

.qr-button {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>